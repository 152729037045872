<template>
  <BlogSummary :list-site="listSite" :categoryName="categoryName" ></BlogSummary>
</template>

<script>
import BlogSummary from "@/components/index/BlogSummary";

export default {
  name: "Category",
  components: {BlogSummary},
  data() {
    return {
      listSite: "/category/category-search",
      categoryName: this.$route.params.categoryName
    }
  },
  watch: {
    //全路径改变时更新分类名
    '$route.fullPath'(){
      this.categoryName = this.$route.params.categoryName
    }
  },
  //注意：next放行之后路由才会改变，应在路由改变后再取this.$route.params赋值，否则取到的是旧值
  // beforeRouteUpdate(to, from, next) {
  //   if (this.$route.name === "category") {
  //     next()
  //     this.categoryName = this.$route.params.categoryName
  //   }
  // }
}
</script>

<style scoped>

</style>