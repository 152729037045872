<template>
  <!--全路径 + 页码为key，防止切换页码时不加载过渡动画-->
  <div id="summary" class="summary" :key="$route.fullPath + summaryConfig.params.pageNum">
    <summary v-for="item in list"  class="summary-item">
      <!--左侧-->
      <div class="summary_left left_radius">

        <router-link :to="`/blog/${item.id}`">
          <img :src=item.firstPicture
               class="post_bg entered loading">
        </router-link>
      </div>
      <!--右侧-->
      <div class="summary-right">
        <!--行1-->
        <div class="right-column-1">
          <div class="summary-info">
            <!--标题-->
            <h1 style="text-align: center;
            word-break: break-all;
            padding: 0 5px 0 20px;
            margin-top: 5px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;">
              <router-link :to="`/blog/${item.id}`" class="summary-title">
                {{ item.title }}
              </router-link>

            </h1>
            <!--分类信息-->
            <div class="summary-category-wrap">
                <span class="summary-category">
                  <i class="fas fa-calendar-alt"></i>
                  <a>{{ item.createTime | dateFormat() }}</a>
                  <span></span>
                  <i class="fas fa-eye"></i>
                  <a>{{ item.views }}</a>
                  <span></span>
                  <i class="fa-solid fa-pen-to-square"></i>
                  <a>字数≈{{ item.words }}字</a>
                  <span></span>
                  <i class="fa fa-clock"></i>
                  <a>阅读时长≈{{ item.readTime }}分</a>
                </span>
            </div>
          </div>
          <!---->
          <!--书签-->
          <div :style="{width: item.top == 1 ? '15%' : '5%'}">
            <router-link :to="`/category/${item.category.categoryName}`" class="mark-vertical mark-color" style="left: 1px">{{ item.category.categoryName }}</router-link>
            <a v-if="item.top" class="mark-vertical mark-color-top" style="margin-right: 10px">置顶</a>
          </div>
        </div>
        <!--行2：概要-->
        <div class="content">
          <p>
            {{ item.description }}
          </p>
        </div>
        <!--行3：底部标签-->
        <div class="summary-tag-wrap">
          <el-divider></el-divider>
          <span v-for="(tag, index) in item.tag" :key="index" class="summary-category">
            <svg style="width: 1.25rem;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M908.2368 463.8208l-156.5696 441.856c-18.8928 53.3504-77.568 81.152-130.816 61.952l-369.152-132.8128c-58.9312-21.1968-85.0432-89.9584-55.0912-144.9472l38.4512-70.5536 278.7328 132.0448 394.4448-287.5392z"
                    fill="#7A7AF9"></path>
                <path
                    d="M117.2992 450.4064l374.784-370.4832c19.968-19.712 47.616-29.5936 75.5712-26.9824l271.2576 25.6c45.7728 4.3008 81.6128 41.216 84.6336 87.04l17.152 259.7888c1.792 27.3408-8.2944 54.1184-27.6992 73.4208l-373.248 371.712c-40.2432 40.0896-105.2672 40.192-145.664 0.3072L117.3504 597.504c-41.0112-40.448-41.0112-106.5984-0.0512-147.0976z"
                    fill="#CCDAFF"></path>
                <path
                    d="M767.0784 227.2256m-60.1088 0a60.1088 60.1088 0 1 0 120.2176 0 60.1088 60.1088 0 1 0-120.2176 0Z"
                    fill="#7A7AF9"></path>
                <path
                    d="M696.2176 415.5392l-117.9648-4.2496-4.4032-116.1728a33.26464 33.26464 0 0 0-34.5088-32 33.29024 33.29024 0 0 0-32 34.5088l3.584 94.9248-37.376-37.7344a33.24928 33.24928 0 0 0-47.0528-0.2048 33.24928 33.24928 0 0 0-0.2048 47.0528l57.7536 58.2656L450.56 493.8752 393.1136 435.9168a33.24928 33.24928 0 0 0-47.0528-0.2048 33.24928 33.24928 0 0 0-0.2048 47.0528l58.0096 58.5216-36.0448 36.608a33.24928 33.24928 0 0 0 0.3584 47.0528 33.11104 33.11104 0 0 0 23.3472 9.5744c8.6016 0 17.2032-3.328 23.7056-9.9328l35.4304-35.9936 55.9104 56.4224a33.1776 33.1776 0 0 0 23.6544 9.8304 33.24928 33.24928 0 0 0 23.6544-56.6784l-56.5248-57.0368 33.4336-33.9456 56.2176 56.7296a33.1776 33.1776 0 0 0 23.6544 9.8304 33.24928 33.24928 0 0 0 23.6544-56.6784l-38.1952-38.5024 97.6384 3.5328h1.2288c17.8176 0 32.6144-14.1312 33.2288-32.0512a33.2288 33.2288 0 0 0-32-34.5088z"
                    fill="#7A7AF9"></path>
            </svg>
            <router-link :to="`/tag/${tag.tagName}`">{{ tag.tagName }}</router-link>
          </span>
        </div>
      </div>
    </summary>

    <el-pagination
        :current-page="this.summaryConfig.params.pageNum"
        :page-size="5"
        :total="total"
        background
        layout="prev, pager, next"
        style="text-align: center;margin: 20px 0"
        @current-change="changePage">
    </el-pagination>


  </div>
</template>

<script>
export default {
  name: "blogSummary",
  props: {
    listSite: String,
    tagName: String,
    categoryName: String,
  },
  data() {
    return {
      total: null,
      list: null,
      summaryConfig: {
        params: {
          pageNum: 1,
        }
      }
    }
  },
  created() {
    if (this.$route.name === "tag") {
      this.summaryConfig.params.tagName = this.tagName
    } else if (this.$route.name === "category") {
      this.summaryConfig.params.categoryName = this.categoryName
    }
    this.getSummary();
  },
  watch: {
    //跳转标签时blogSummary组件会被重用，不会触发created，需要重新获取博客列表
    //blogSummary不是路由组件（没有在router里注册），无法使用beforeRouteUpdate钩子
    //故通过监听$route.fullPath判断是否跳转标签
    '$route.fullPath'() {
      if (this.$route.name === "tag") {
        this.summaryConfig.params.tagName = this.tagName
        this.summaryConfig.params.pageNum = 1
        this.getSummary()
      } else if (this.$route.name === "category") {
        this.summaryConfig.params.categoryName = this.categoryName
        this.summaryConfig.params.pageNum = 1
        this.getSummary()
      }
    }
  },
  methods: {
    getSummary() {
      this.$request.get(this.listSite, this.summaryConfig).then(res => {
        this.total = res.data.total
        this.$store.commit("setHeaderInfo", {total: this.total})
        this.list = res.data.list
      })
    },
    changePage(pageNum) {
      this.summaryConfig.params.pageNum = pageNum
      this.getSummary()
      //  页码改变时，如果在home，回滚至首图下方，否则回滚到顶部
      if (this.$route.name === 'home') {
        let photoH = document.querySelector('.header').offsetHeight
        window.scrollTo({top: photoH - 56, behavior: "smooth"})
      } else {
        document.documentElement.scrollTop = 0
      }
    },
  },


}
</script>

<style scoped>

.summary-item {
  animation: article 1s;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 2.5rem;
  height: 280px;
  border-radius: 8px;
  background: #fff;
  transition: all 0.3s;
}

.summary-item:hover {
  box-shadow: 0 4px 8px 6px rgb(7 17 27 / 15%);
}

.summary-item:hover img.post_bg {
  transform: scale(1.2) rotate(5deg);
}

img.post_bg {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  transition: all 0.6s;
  object-fit: cover;
}

.left_radius {
  border-radius: 8px 0 0 8px;
}

.summary_left {
  overflow: hidden;
  width: 40%;
  height: 280px;
}

.summary-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%
}

.right-column-1 {
  display: flex;
  flex-direction: row;
  height: 40%;
  width: 100%;
}

.summary-info {
  display: inline-block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.summary-title {
  margin-bottom: 0.3rem;
  color: #4c4948;
  font-size: 1.2rem;
  line-height: 1.4;
  transition: all 0.2s ease-in-out;
}

.summary-title:hover {
  color: #49b1f5 !important;
}

.summary-category-wrap {
  font-size: 88%;
  padding: 0 0 0 40px;
}

.summary-category {
  margin: 0 0.8rem 0 0;
}

.summary-category > span {
  margin: 0 0.3em;
}

.summary-category > a {
  color: #737373;
  text-decoration: none;
  word-wrap: break-word;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  overflow-wrap: break-word;
}

.summary-category > a:hover {
  color: #49b1f5 !important;
}

.summary-tag-wrap {
  height: 10%;
  padding: 0 20px;
  font-size: 88%;
  color: #858585;
}

.summary-tag-wrap > span > a {
  position: relative;
  bottom: 5.7px;
  left: 2px;
}

/deep/ .el-divider--horizontal {
  margin: 0 0 5px 0;
}

i {
  margin: 0 0.2rem 0 0;
}


.content {
  /*外观*/
  color: rgb(76, 73, 72);
  padding: 0 35px 0 40px;
  height: 51.5%;
  /*布局*/
  bottom: 15px;
  position: relative;
}


.content > p {
  margin: 0;
  line-height: 1.8;
  word-break: break-all;
  /*限制行数与行高*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

}

/*分类图标颜色*/
.fa-eye {
  color: #9370db;
}

.fa-pen-to-square {
  color: #70C1B3;
}

.fa-clock {
  color: #FFB4A2;
}

.fa-calendar-alt {
  color: #48a3c7;
}

/*垂直书签*/
.mark-vertical {
  float: right; /*水平方向放多个的设置*/
  position: relative;
  padding: 5px 0 5px 0;
  width: 25px;
  color: #fff;
  text-align: center;
}

.mark-vertical::after {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  border-style: solid;
  border-width: 0 13px 13px 13px;
}

/*可设置不同颜色*/
.mark-color::after {
  border-color: #00d9a9 #00d9a9 transparent #00d9a9;
}

.mark-color {
  background: #00d9a9;
}

.mark-color:hover::after {
  transition: all 0.2s;
  border-color: #01ab83 #01ab83 transparent #01ab83;
}

.mark-color:hover {
  transition: all 0.2s;
  background: #01ab83;
}

.mark-color-top::after {
  border-color: #ff7242 #ff7242 transparent #ff7242;
}

.mark-color-top {
  background: #ff7242;
}

</style>